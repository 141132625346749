<template>
	<div>
		<!-- 指标库 -->
		<div class="target-wrapper" v-if="targetList.visible">
			<div class="item-title bottom-solid">
				<span>指标库</span>
			</div>

			<div class="target-wrapper-inner">
				<div class="target-button">
					<el-button
						icon="el-icon-plus"
						type="primary"
						@click="targetItemEditButton()"
					>添加</el-button>
				</div>

				<!-- 指标库列表 -->
				<div class="target-table">
					<el-table
						:data="targetList.tableData"
						border
					>
						<el-table-column
							label="序号"
							type="index"
							align="center"
							width="80"
						></el-table-column>
						<el-table-column
							prop="code"
							align="center"
							min-width="100"
							label="编号"
						></el-table-column>
						<el-table-column
							prop="guideName"
							show-overflow-tooltip
							min-width="200"
							label="指标名称"
						></el-table-column>
						<el-table-column
							prop="copyFrom"
							align="center"
							min-width="100"
							label="来源"
						></el-table-column>

						<el-table-column
							align="center"
							min-width="100"
							label="排序"
						>
							<template slot-scope="data">
								<el-button
									v-if="data.$index === 0 && data.store.states.data.length >= 2"
									@click="handleSort(data, 1)"
									type="text"
								>下移</el-button>
								<el-button
									v-else-if="data.$index === data.store.states.data.length - 1 && data.store.states.data.length >= 2"
									@click="handleSort(data)"
									type="text"
								>上移</el-button>
								<template v-else-if="data.store.states.data.length >= 2">
									<el-button
										@click="handleSort(data)"
										type="text"
									>上移</el-button>
									<el-button
										@click="handleSort(data, 1)"
										type="text"
									>下移</el-button>
								</template>
							</template>
						</el-table-column>

						<el-table-column
							prop="publicTime"
							label="发布时间"
							align="center"
							width="110"
							>
						</el-table-column>
						<el-table-column
							label="操作"
							align="center"
							width="140"
							fixed="right">
							<template slot-scope="data">
								<div class="table-handle" align="center">
									<el-button
										@click="targetItemDetails(data.row)"
										type="text"
									>配置</el-button>
									<i>|</i>
									<el-button type="text" @click="targetItemEditButton(data.row)"
									>编辑</el-button>
									<i>|</i>
									<el-button type="text" @click="targetItemDelete(data)"
									>删除</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>

					<!-- 分页 -->
					<div class="target-pagination">
						<el-pagination
							align="right"
							background
							@size-change="targetListSizeChange"
							@current-change="targetListCurrentChange"
							:current-page.sync="targetList.current"
							:page-sizes="[10, 20, 30, 40 ,50]"
							:page-size="targetList.size"
							layout="prev, pager, next, sizes, jumper"
							:total="targetList.total"

						></el-pagination>
					</div>
				</div>
			</div>
			</div>

		<!-- 删除确认窗口 -->
		<el-dialog title="操作确认" :visible="false">
			<div class="message-box">
				<div class="box-wrap">
					<div class="icon">
						<i class="el-icon-question"></i>
					</div>
					<div class="info">
						<p>是否确定删除数据？</p>
						<span>是否确认删除该条数据，删除后，将无法恢复。</span>
					</div>
				</div>
			</div>
		</el-dialog>


		<!-- 指标库添加窗口 -->
		<el-dialog
			:visible.sync="targetItem.visible"
			:title="targetItem.title"
			class="target-item-edit">
			<div class="content">
				<el-form
					ref="targetItem"
					:model="targetItem.data"
					:rules="targetItem.rules"
					label-position="right"
					label-width="5.20833vw"
				>
					<el-form-item
						prop="code"
						label="编号："
					>
						<el-input
							v-model="targetItem.data.code"
							title="请填写编号名称，支持大小写字母、数字、下划线组合"
							placeholder="请填写编号名称，支持大小写字母、数字、下划线组合"
							type="text"
							:maxlength="10"
						></el-input>
					</el-form-item>
					<el-form-item
						prop="guideName"
						label="名称："
					>
						<el-input
							v-model="targetItem.data.guideName"
							title="请输入名称"
							placeholder="请输入名称"
							type="text"
							:minlength="2"
							:maxlength="50"
						></el-input>
					</el-form-item>
					<el-form-item
						prop="copyFrom"
						label="来源："
					>
						<el-input
							v-model="targetItem.data.copyFrom"
							title="请输入来源"
							placeholder="请输入来源"
							type="text"
							:maxlength="200"
						></el-input>
					</el-form-item>
					<el-form-item
						prop="publicTime"
						label="发布时间："
					>
						<el-date-picker
							v-model="targetItem.data.publicTime"
							type="date"
							align="left"
							placeholder="选择日期"
						></el-date-picker>
					</el-form-item>
					<el-form-item
						prop="description"
						label="描述："
						class="head-item-describe"
					>
						<el-input
							v-model="targetItem.data.description"
							show-word-limit
							placeholder="请填写描述内容..."
							type="textarea"
							:maxlength="100"
							class="dialog-target-description-after"
						></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="footer" align="right">
				<el-button
					size="mini"
					@click="targetItem.visible = false"
				>取消</el-button>
				<el-button
					size="mini"
					@click="targetItemAddSubmit()"
					type="primary"
				>保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
const toolbarOptions = [
	["bold", "italic", "underline", "strike"], // toggled buttons
	["blockquote", "code-block"],

	[{ header: 1 }, { header: 2 }], // custom button values
	[{ list: "ordered" }, { list: "bullet" }],
	[{ script: "sub" }, { script: "super" }], // superscript/subscript
	[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
	[{ direction: "rtl" }], // text direction

	[{ size: ["small", false, "large", "huge"] }], // custom dropdown
	[{ header: [1, 2, 3, 4, 5, 6, false] }],

	[{ color: [] }, { background: [] }], // dropdown with defaults from theme
	[{ font: [] }],
	[{ align: [] }],
	// ["link", "image", "video"],
	["clean"], // remove formatting button
];
import tool from '@/utils/tool';
export default {
	name: "targetManage",

	created() {
		this.pageLoading = this.$loading({
			lock: true,
			fullscreen: true,
			text: '加载中',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		});
		this.getTargetList();
	},
	data() {
		return {

			//指标库
			targetList: {
				visible: true, //指标列表显隐
				// loading: true,
				tableData: [], //指标数据
				//当前页
				current: 1,
				//每页条数
				size: 10,
				//总条数
				total: 0,
				//总页数
				pages: 0,
				//页码显示数量
				PagerCount: 10
			},
			pageLoading: null, //页面loading

			//添加目标传入的父级数据
			targetItemEditVisible: false,
			targetTierVisibleVisible: false,
			targetInsertVisible: false,

			eSize: "mini",
			targetItemEditTitle: "",
			targetListTableData: [], //指标列表数据存放

			targetDeployTier: {
				catalogueCode: '',
				catalogueName: '',
				dialogTitle: ''
			},
			targetInsertData: {
				step: 1,
				dialogTitle: '',
				activeName: '',
				catalogueCode: '', //添加目标-编号
				catalogueName: '', //添加目标-标题
				targetInsertLoading: false,//添加目标显隐
				advice: [
					{}
				],
				adviceIndex: 0,
				targetDescribeTableDatas: [[{}]],
				targetDescribeTableDataIndexs: [],
				tabName: '',
				tabAdd: {
					tagName: '',
				},
				tabAddRules: {
					tagName: { required: true, message: "请输入名称，支持汉字、字母、数字组合" }
				},
				dimensionality: '0',
				//字典返回列表
				eduDictList: [],
				//字典名字
				eduDictName: ['age_scope', 'class_level', ''],
				targetFulfilTabsVisible: false,
				targetFulfilTabsIndex: '0',
				targetFulfilTabsValue: '0',
				targetFulfilTabsNameValue: '',
				//教学实践标签
				targetFulfilTabs: [],
				targetFulfiTabsData: []
			},
			targetDeployTableData: [],
			targetItem: {
				visible: false, //指标配置详情修改窗口显隐
				title: '',
				data: { //指标库配置详情
					code: '',
					guideName: '',
					copyFrom: '',
					publicTime: '',
					description: ''
				},
				details: {}, //指标库配置详情数据
				deployDirList: [],
				rules: { //data 验证格式
					code: [
						{ pattern: /^[0-9a-zA-Z_]*$/, message: "只支持大小写字母、数字、下划线组合" },
						{ min: 2, max: 10, message: "2-10个汉字长度" }
					],
					guideName: [
						{ required: true, message: "请输入名称，支持汉字、字母、符号、数字组合" },
						{ min: 2, max: 50, message: "2-50个汉字长度" }
					],
					copyFrom: [
						{
							message: "格式错误：只支持汉字、字母、符号、数字组合"
						},
						{ min: 2, max: 15, message: "2-15个汉字长度" }
					],
					description: { max: 100, message: "100个汉字长度" }
				}
			},
			//目标数据
			target: {
				data: {},
				fulfil: {
					arciletAdd: [],
					articleDatas: [],
					dialog: {
						buttonAddVisible: false,
						targetPracticeInsertRules: {
							practiceTitle: [
								{ required: true, message: "请输入名称，支持汉字、字母、数字组合" },
								{ min: 2, max: 50, message: "请输入名称，2-50个汉字长度" }
							]
						},
						form: {
							practiceTitle: '',
							practiceContent: '',
							files: [],
							isDownload: '1'
						}
					}
				}
			},
			uploadUrl: "", //上传url
			editorOption: { //富文本配置
				modules: {
					toolbar: {
						container: toolbarOptions, // 工具栏
						handlers: {
							image: function (value) {
								if (value) {
									alert("自定义图片");
								} else {
									this.quill.format("image", false);
								}
							},
						}
					},
				},
				placeholder: '',
			}
		}
	},
	methods: {
		/**
		 * 指标库-添加按钮
		 * @param {type} data
		 */
		targetItemEditButton(data) {
			this.targetItem.visible = true;
			if (typeof data === 'object') {
				let { id, guideName, copyFrom, publicTime, description, code } = data;
				this.targetItem.title = "编辑";
				this.targetItem.status = 'update';
				this.targetItem.data = { id, guideName, copyFrom, publicTime, description, code };
				this.targetItem.details = this.targetItem.data;
				this.targetItem.data.publicTime = new Date(this.targetItem.data.publicTime);
			} else {
				this.targetItem.title = "添加";
				this.targetItem.status = 'insert';
				this.targetItem.data = {
					code: '',
					guideName: '',
					copyFrom: '',
					publicTime: new Date(),
					description: ''
				};
				this.$refs.targetItem && this.$refs.targetItem.resetFields();
			}
		},
		//指标库添加/编辑-提交
		targetItemAddSubmit(status) {
			this.$refs['targetItem'].validate(v => {
				if (v) {
					let { data } = this.targetItem;
					if (status) {
						this.targetItem.status = status;
					}
					status = this.targetItem.status === 'insert' ? '添加' : '修改';
					//时间格式转换
					data.publicTime = data.formatTime = tool.formatTime(data.publicTime);
					this.$api[status === '添加' ? 'postTargetItem' : 'putTargetItem'](data)
						.then(res => {
							console.log(res);
							if (res.data.code) {
								this.$message.error(res.data.msg);
								return;
							}
							this.pageLoading = this.$loading({
								lock: true,
								fullscreen: true,
								text: '加载中',
								spinner: 'el-icon-loading',
								background: 'rgba(0, 0, 0, 0.7)'
							});
							this.getTargetList();
							this.targetItem.visible = false;
							this.$refs['targetItem'].resetFields();
							this.$message.success(`指标库${status}成功`);
						})
						.catch(e => {
							this.$message.error(`指标库${status}错误`);
							console.error(e);
						})
						.finally(_ => {

						});
				}
			});
		},
		// 删除窗口
		buttonDelete({ message } = { message: '是否确定删除数据？'}) {
			message = `${message}<br><span>是否确认删除该条数据，删除后，将无法恢复。</span>`
			return this.$confirm("", "操作确认", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				customClass: "message-box",
				iconClass: "el-icon-question",
				dangerouslyUseHTMLString: true,
				message
			});
		},
		//获取目标列表
		getTargetList() {

			let {size, current} = this.targetList;
			this.$api.getTargetList({
				size,
				current
			}).then(res => {
				if (res.status === 200) {
					this.pageLoading.close()
					// this.targetList.loading = false
					if (res.data.code === 0) {
						let { records, size, current, total, pages } = res.data.data;
						this.targetList.tableData = records;
						this.targetList.total = total;
						return;
					}
				}
				this.$message.error('获取指标库列表失败');
			}).catch(e => {
				// this.targetList.loading = false
				this.pageLoading.close()
				this.$message.error('获取指标库列表错误');
				console.error(e);
			})
		},
		//指标库删除按钮
		targetItemDelete(data) {
			this.buttonDelete()
				.then(_ => {
					this.$api.deleteTargetItem(data.row.id)
						.then(res => {
							if (res.data.code === 0) {
								this.pageLoading = this.$loading({
									lock: true,
									fullscreen: true,
									text: '加载中',
									spinner: 'el-icon-loading',
									background: 'rgba(0, 0, 0, 0.7)'
								});
								this.getTargetList();
								this.$message.success('数据删除成功');
								return;
							}
							this.$message.error(res.data.msg);
						})
						.catch(e => {
							this.$message.error('数据删除错误');
							console.log(e);
						})
				});
		},

		//指标库配置

		targetItemDetails(data) {
			let { id } = data;

			this.$router.push({
				'name': 'targetCollocate',
				params: { id }
			});

		},
		//指标库单页条数总量改变时
		targetListSizeChange(size) {
			this.targetList.size = size;
			this.targetList.current = 1;
			this.getTargetList();
		},
		//指标库页数改变时
		targetListCurrentChange(page) {
			this.targetList.current = page;
			this.getTargetList();
		},
		/**
		 * 列表排序替换
		 * @param {object} data
		 * @param {boolean} status false为上移,true为下移
		 */
		handleSort(data, status) {
			let index = data.$index;
			let items = data.store.states.data;
			let moveItem;
			let { id, sort } = data.row;
			let moveId, moveSort;

			if (items.length < 2) return;
			status ? index++ : --index;
			moveItem = items[index];
			moveId = moveItem.id;
			moveSort = moveItem.sort;

			this.$api.postEdutargetGuideSort({
				id1: id,
				sort1: sort,
				id2: moveId,
				sort2: moveSort,
			})
				.then(res => {
					if (res.data.code) {
						this.$message.error(res.data.msg);
						return;
					}

					items.splice(data.$index, 1, ...items.splice(index, 1, items[data.$index]));
					this.$message.success('排序替换成功');
				})
				.catch(e => {
					this.$message.error('排序替换错误');
					console.error(e);
				})
		},




		a(data) {
			console.log(data);
		}
	}

};
</script>

<style lang="scss" scoped>
$colorGrey: #999;
$colorGrey_2: #666;
$colorGrey_3: #e9e9e9;
$borderRadius: 5px;

.el-dialog {
	border-radius: $borderRadius;
	overflow: hidden;
	.el-dialog__body {
		padding: 0;
		.el-dialog__title {
			color: $colorGrey_2;
		}
		.content {
			padding: 30px 70px 30px 60px;
		}
	}
	.footer {
		padding: 20px;
		border-top: 1px solid #ececec;
		.el-button {
			padding: 8px 25px;
		}
	}
}

.target-item-edit {

	.el-textarea {
		textarea {
			height: 120px;
		}
	}
}

/deep/.message-box {
	width: 570px;
	.el-message-box__header {
		padding: 18px 14px;
		background: #f5f5f5;
		box-sizing: border-box;
		.el-message-box__title {
			color: $colorGrey_2;
			font-size: 14px;
			font-weight: 700;
			.el-message-box__headerbtn {
				top: 18px;
			}
			.el-icon-close {
				color: $colorGrey_2;
				font-weight: bold;
			}
		}
	}

	/deep/.el-message-box__content {
		padding: 60px 40px;
		.el-message-box__container {
			.el-icon-question {
				color: #ff9900;
			}
		}
		.el-message-box__message {
			p {
				color: $colorGrey_2;
				font-size: 16px;
				> span {
					color: $colorGrey;
					font-size: 14px;
				}
			}
		}
	}

	.el-message-box__btns {
		padding: 15px 20px;
		border-top: 1px solid #eaeaea;
		.el-button {
			padding: 8px 20px;
		}
	}

	.box-wrap {
		display: flex;
		.icon {
			font-size: 2em;
			color: #ff9900;
		}
		.text {
			> p {
				color: $colorGrey_2;
				font-size: 16px;
			}
			> span {
				color: $colorGrey;
				font-size: 14px;
			}
		}
	}
}

.target-wrapper {
	background: #fff;
	.target-wrapper-inner {
		padding: 0 30px;
	}
	.item-title {
		position: relative;
		padding: 20px 30px;
		margin-bottom: 20px;
		font-size: 20px;
		font-weight: bold;
		&:after {
			bottom: 1px;
			height: 1px;
		}
		span {
			position: relative;
			&:after {
				z-index: 10;
				position: absolute;
				left: 50%;
				bottom: 3px;
				content: "";
				width: 24px;
				height: 3px;
				background: #617def;
				transform: translate(-50%, 20px);
			}
		}
	}

	.bottom-solid:after {
		position: absolute;
		bottom: -1px;
		left: 0;
		content: "";
		width: 100%;
		height: 1px;
		background:rgba(112, 112, 112, 0.15);
		// transform: translateX(-12px);
	}

	.item-info {
		$info-solid-width: 20px;
		position: relative;
		margin-bottom: $info-solid-width;
		padding-bottom: $info-solid-width;
		&.bottom-solid:after {
			bottom: -20px;
			height: $info-solid-width;
		}
	}

	.target-button {
		margin-bottom: 16px;
	}

	.target-table {
		position: relative;
		.table-handle {
			i {
				padding: 0 4px;
				color: $colorGrey_3;
			}
		}
	}

	.target-pagination {
		padding: 40px 0;
	}
	.el-pagination.is-background {
		.btn-prev,
		.btn-next,
		.el-pager li {
			background: #fff;
			border: 1px solid $colorGrey_3;
		}
	}
}

.target-insert {
	.input-box {
		input {
			width: 600px;
		}
	}
	.el-steps {
		justify-content: center;
		.el-step {
			padding: 20px 0 40px;
		}
	}
	.el-dialog__footer {
		padding: 10px 40px 40px;
	}
	.el-tabs {
		padding-left: 70px;
	}
	.table-width-calc {
		margin-left: 70px;
		width: calc(100% - 70px);
	}
	.target-fulfil {
		position: relative;
		.el-tabs__header {
			width: calc(100% - 70px);
		}
		.tabs-insert-button {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	.target-fulfil-article {
		padding-left: 70px;
		width: calc(100% - 70px);
		.target-article-item-title {
			display: flex;
			justify-content: space-between;
			.title {
				color: #000;
				font-weight: 700;
			}
			.edit {
				text-align: right;
				width:200px;
				button {
					padding:0;
				}
				i {
					font-weight: 700;
					font-size: 16px;
				}

			}
		}
	}
	.target-article-item-content {
		display: flex;
		margin: 30px 0;
		padding: 20px;
		border: 1px solid #e9e9e9;
		border-radius: 5px;
		.img {
			width: 200px;
			height: 120px;
			background: $colorGrey_3;
		}
		.content {
			padding: 0 20px;
		}
	}
	.target-article-item-files {
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		.target-article-item-file {
			display: flex;
			margin-bottom: 10px;
			margin-right: 10px;
			justify-content: space-between;
			line-height: 67px;
			border-radius: 5px;
			border: 1px solid #f2f2f2;
			&:nth-child(3n) {
				margin-right: 0;
			}
			.type {
				flex-shrink: 0;
				width: 67px;
				height: 67px;
				color: #fff;
				font-size: 16px;
				font-weight: 900;
				text-align: center;
				background: #78aff9;
				border-radius: 5px;
				text-transform: uppercase;
			}
			.file-name {
				padding: 0 20px;
				width: 320px;
				color: $colorGrey_2;
				font-size: 16px;
				font-weight: bold;
			}
			.file-remove {
				padding: 0 30px;
			}
		}
	}
	.target-article-item-add {
		button {
			width: 100%;
		}
	}

	.danger {
		color: #e47470;
	}
}
.dialog-target-fulfil-add {
	.el-dialog__body {
		padding: 40px 80px 40px 40px;
	}
	.quill-editor {
		.ql-editor {
			height: 300px;
		}
		.ql-formats {
			line-height: 24px;
		}
	}
	.target-fulfil-upload-file {
		display: flex;
		.add {
			margin-left: 20px;
		}
	}
	.size-padding {
		padding: 0 10px;
	}
	.target-fulfil-upload-point {
		text-align: center;
		span {
			color: #c72a29;
		}
	}
	.target-fulfil-files{
		.target-fulfil-files-item {
			display: flex;
			padding: 0 40px;
			margin-top: 20px;
			width: 100%;
			box-sizing: border-box;
			.file {
				display: flex;
				line-height: 57px;
				border: 1px solid #f2f2f3;
				border-radius: 5px;
				.type {
					flex-shrink: 0;
					width: 57px;
					height: 57px;
					border-radius: 5px;
					background: #78aff9;
					color: #fff;
					font-size: 16px;
					text-align: center;
				}
				.file-name {
					flex-grow: 1;
					padding: 0 20px;
					width: 500px;
					color: $colorGrey_2;
					font-size: 16px;
					font-weight: bold;
				}
				.file-remove {
					padding: 0 20px;
				}
			}
			.download {
				padding: 0 30px;
				display: flex;
				align-self: center;
			}
		}
	}
	.el-dialog__footer {
		button {
			padding: 12px 40px;
		}
	}
}
.dialog-target-description-after {
	.el-input__count:before {
		content: '已输入';
	}
}

.practice-files-wrap {
	display: flex;
	padding: 10px 0;
	flex-wrap: wrap;
	.file-item {
		margin-right: 80px;
		margin-bottom: 30px;
		border: 1px #e8e8e8 solid;
		border-radius: 4px;
		overflow: hidden;
		&:nth-child(3n) {
			margin-right: 0;
		}
		.file-item-header {
			display: flex;
			padding: 15px;
			height: 80px;
			background: #fff;
			.file-item-header-img {
				>div {
					width: 24px;
					height: 24px;
					background-size: 24px!important;
				}
				.file-pdf {
					background: url(../../../assets/img/file-icon-PDF.png);
				}
				.file-word {
					background: url(../../../assets/img/file-icon-word.png);
				}
				.file-xlsx {
					background: url(../../../assets/img/file-icon-excel.png);
				}

			}
			.file-item-header-name {
				padding-left: 10px;
				width: 200px;
				height: 26px;
				line-height: 26px;
				color: #000;
				font-size: 18px;
				font-weight: bold;
			}
		}
		.file-item-footer {
			position: relative;
			display: flex;
			justify-content: space-between;
			height: 50px;
			&:after {
				position: absolute;
				left: 50%;
				top: 50%;
				content: '';
				width: 1px;
				height: 20px;
				background: #c8c8c8;
				transform: translateY(-50%);
			}
			.el-button {
				width: 50%;
				padding: 3px 10px;
				color: #8C8C8C;
				background: #f7f9fa;
				border-radius: 0;
				&:nth-child(2n) {
					margin-left: 0;
				}
			}
		}
	}
}
</style>