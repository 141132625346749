const tool = {
	/**
	 * 字符串补0，用于时间
	 * @param {number} n
	 * @returns {string}
	 */
	numAddZero(n) {
		return n > 9 ? n : '0' + n;
	},
	/**
	 *	格式化时间
	 * @param {string} time
	 * @returns {string}
	 */
	formatTime(time, status) {
		let date = {};
		let dateStr;
		time = new Date(time);
		date = {
			Y: time.getFullYear(),
			M: this.numAddZero(time.getMonth() + 1),
			D: this.numAddZero(time.getDate()),
			h: this.numAddZero(time.getHours()),
			m: this.numAddZero(time.getMinutes()),
			s: this.numAddZero(time.getSeconds()),
		};
		switch (status) {
			case 1:
				dateStr =  `${date.Y}-${date.M}-${date.D} ${date.h}:${date.m}:${date.s}`;
				break;
			default:
				dateStr =  `${date.Y}-${date.M}-${date.D}`;
		}

		return dateStr;
	},
};
export default tool;